<template>
  <div
    v-if="selectedFirstLevel.children && selectedFirstLevel.children.length"
    ref="classifyContainer"
    class="classify-container bgf flex-s0"
  >
    <div class="pb150">
      <div class="part bgf5" />
      <div
        v-if="String(selectedFirstLevel.only_level) === '2'"
        class="flex flex-direction-column"
      >
        <div
          v-for="item in selectedFirstLevel.children"
          :key="item.id"
          class="tab-item three-level cl69 fs12 lh17 wb"
          :class="{ 'three-level-active': selectedData.id === item.id }"
          @click="onSelect(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        v-if="String(selectedFirstLevel.only_level) === '3'"
        class="flex flex-direction-column"
      >
        <div
          v-for="(item, index) in selectedFirstLevel.children"
          :key="item.id"
          class="flex flex-direction-column"
        >
          <div
            class="tab-item second-level cl29 fs12 fw500 relative mb6 lh17 wb"
            @click="onSecondLevel(item)"
          >
            {{ item.name }}
          </div>
          <div v-if="item.children && item.children.length">
            <div
              v-for="child in item.children"
              :key="child.id"
              class="tab-item three-level cl69 fs12 lh17 wb"
              :class="{ 'three-level-active': selectedData.id === child.id }"
              @click="onSelect(child)"
            >
              {{ child.name }}
            </div>
          </div>
          <div
            v-if="index !== selectedFirstLevel.children.length - 1"
            class="part bgf5"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'

export default {
  props: {
    selectedFirstLevel: {
      // 选中的一级分类数据
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      contentScrollH: 0, // 导航区高度
      scrollTop: 0, // 竖向滚动条位置
      threeLevelIndex: 0, // 记录遍历index
      selectedData: {}, // 子级选中的项
      firstLevelData: {} // 一级分类数据
    }
  },
  watch: {
    firstLevelData: {
      handler(newval, oldval) {
        if (
          newval &&
          (!oldval ||
            newval.id !== oldval.id ||
            newval.only_level !== oldval.only_level)
        ) {
          if (
            String(newval.only_level) === '2' &&
            newval.children &&
            newval.children[0]
          ) {
            this.selectedData = newval.children[0]
          } else if (
            newval.children &&
            newval.children[0] &&
            newval.children[0]['children'] &&
            newval.children[0]['children'][0]
          ) {
            this.selectedData = newval.children[0]['children'][0]
          } else {
            this.selectedData = {}
          }
          this.getMaterialList()
        }
      },
      deep: true,
      immediate: true
    },
    selectedFirstLevel: {
      handler(newval) {
        this.firstLevelData = newval
        const _this = this
        this.$nextTick(() => {
          _this.getScrollWidth()
        })
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {},
  activated() {
    this.$nextTick(() => {
      this.$refs.classifyContainer.scrollTop = this.scrollTop
    })
  },
  methods: {
    // 选择二级分类
    onSecondLevel({ children }) {
      const _this = this
      if (
        children &&
        children.length &&
        this.selectedData.id !== children[0].id
      ) {
        this.selectedData = children[0]
        const scrollTop =
          children[0].top - this.contentScrollH / 2 + children[0].height / 2
        this.$nextTick(() => {
          if (_this.$refs.classifyContainer) {
            _this.scrollTop = scrollTop
            _this.$refs.classifyContainer.scrollTop = scrollTop
          }
        })

        this.getMaterialList()
      }
    },
    // 选择三级分类
    onSelect(data = {}) {
      const _this = this
      if (this.selectedData.id !== data.id) {
        const scrollTop = data.top - this.contentScrollH / 2 + data.height / 2
        this.$nextTick(() => {
          if (_this.$refs.classifyContainer) {
            _this.scrollTop = scrollTop
            _this.$refs.classifyContainer.scrollTop = scrollTop
          }
        })
        this.selectedData = data
        this.getMaterialList()
      }
    },
    // 请求分类的素材列表
    getMaterialList() {
      const { id, content } = this.selectedData
      if (id) {
        bus.emit('getMaterialList', {
          key: 'first',
          group_id: id,
          guideText: content
        })
      }
    },
    // 获取标题区域高度，和每个子元素节点的高度
    getScrollWidth() {
      const { selectedFirstLevel } = this
      const classifyContainer = document.querySelector('.classify-container')
      if (classifyContainer)
        this.contentScrollH = classifyContainer.offsetHeight
      if (!selectedFirstLevel.children) return false
      const childrenData = [...selectedFirstLevel.children]
      const threeLevelDoms = document.querySelectorAll('.three-level')
      if (threeLevelDoms) {
        if (String(selectedFirstLevel.only_level) === '2') {
          for (let i = 0; i < childrenData.length; i++) {
            childrenData[i]['height'] = threeLevelDoms[i]['offsetHeight']
            childrenData[i]['top'] = threeLevelDoms[i]['offsetTop']
          }
        } else {
          this.threeLevelIndex = 0
          for (let index = 0; index < childrenData.length; index++) {
            for (let i = 0; i < childrenData[index]['children'].length; i++) {
              childrenData[index]['children'][i]['height'] =
                threeLevelDoms[this.threeLevelIndex]['offsetHeight']
              childrenData[index]['children'][i]['top'] =
                threeLevelDoms[this.threeLevelIndex]['offsetTop']
              this.threeLevelIndex = this.threeLevelIndex + 1
            }
          }
        }
        this.firstLevelData = { ...this.firstLevelData, children: childrenData }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.mb6 {
  margin-bottom: 6px;
}
.pb150 {
  padding-bottom: 150px;
}
.lh17 {
  line-height: 17px;
}
.classify-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  z-index: 1;

  .tab-item {
    width: 100%;
    line-height: 15px;
    padding: 6px 2px;
    text-align: center;
  }
  .second-level {
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 90%;
      height: 1px;
      margin: 0 auto;
      content: '';
      border-radius: 1px;
      background-color: #e5e6eb;
    }
  }
  .three-level {
    padding-left: 5px;
  }
  .three-level-active {
    position: relative;
    color: @theme-color;
    font-weight: 500;

    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      content: '';
      background-color: @theme-color;
    }
  }
  .part {
    height: 6px;
  }
}
</style>
